@use '@/styles/utils/mixins.scss' as *;

.customTabsWrapper {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  .sectionHeadingContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }
    .sectionDescription {
      grid-column: span 2;
      @include tab() {
        grid-column: span 1;
      }
    }
  }

  .customTabsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    width: 100%;
    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }

    .tabBar {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      @include tab() {
        flex-direction: row;
        overflow-x: auto;
        width: 100%;
        padding-bottom: 0.25rem;
      }
    }

    .tabButton {
      display: flex;
      align-items: baseline;
      font-size: 1.5rem;
      gap: 0.25rem;
      color: $neutral07;
      border: none;
      background: none;
      cursor: pointer;
      text-align: left;
      width: max-content;
      position: relative;
      font-weight: 500;

      h3 {
        font-weight: normal;
        font-size: inherit;
      }

      @include tab() {
        font-size: 1rem;
        text-wrap: nowrap;
        font-weight: 400;
      }

      .productType {
        font-size: 0.875rem;

        @include tab() {
          font-size: 1rem;
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 0.063rem;
        width: 0;
        background-color: $primary;
        transition: width 0.3s;
      }

      &:hover::before,
      &:focus::before,
      &:active::before,
      &.active::before {
        width: 100%;
        transition: width 0.3s;
      }

      &:active,
      &:focus,
      &:hover,
      &.active {
        color: $primary;
      }

      &.active {
        @include tab() {
          font-weight: bold;
        }
      }
    }

    .tabContent {
      grid-column: span 2;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      .tabContentInner {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }

      @include tab() {
        grid-column: span 1;
      }
    }
  }
}
