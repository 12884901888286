@import './vars';
/* all media settings */
/*
  @mixin response
  @param $prefix {'max' or 'min'}
  @param $media {number}
 */
@mixin response($prefix, $media) {
  @media only screen and (#{$prefix}-width: #{$media}px) {
    @content;
  }
}

/*  using '@mixin response' to create own @mixins for max-width of displays */
@mixin mob-galaxyS5() {
  @include response(max, $mob-galaxyS5) {
    @content;
  }
}
@mixin mob-iphone6() {
  @include response(max, $mob-iphone6) {
    @content;
  }
}
@mixin mob() {
  @include response(max, $mob) {
    @content;
  }
}
@mixin mob-tab() {
  @include response(max, $mob-tab) {
    @content;
  }
}
@mixin tab() {
  @include response(max, $tab) {
    @content;
  }
}
@mixin tab-des() {
  @include response(max, $tab-des) {
    @content;
  }
}
@mixin des() {
  @include response(max, $des) {
    @content;
  }
}

@mixin des-max() {
  @include response(max, $des-max) {
    @content;
  }
}

/*  using '@mixin response' to create own @mixins for min-width of displays */
@mixin mob-galaxyS5-min() {
  @include response(min, $mob-galaxyS5 + 1) {
    @content;
  }
}
@mixin mob-iphone6-min() {
  @include response(min, $mob-iphone6 + 1) {
    @content;
  }
}
@mixin mob-min() {
  @include response(min, $mob + 1) {
    @content;
  }
}
@mixin mob-tab-min() {
  @include response(min, $mob-tab + 1) {
    @content;
  }
}
@mixin tab-min() {
  @include response(min, $tab + 1) {
    @content;
  }
}
@mixin tab-des-min() {
  @include response(min, $tab-des + 1) {
    @content;
  }
}
@mixin des-min() {
  @include response(min, $des + 1) {
    @content;
  }
}

@mixin des-max-min() {
  @include response(min, $des-max + 1) {
    @content;
  }
}

// Mixin to generate margin and padding classes with max-width media queries
@mixin generate-spacing($property, $css-property, $spacers, $breakpoint: null) {
  @each $key, $value in $spacers {
    @if $breakpoint == null {
      .#{$property}-#{$key} {
        #{$css-property}: #{$value} !important;
      }
    } @else {
      @media (max-width: map-get($breakpoints, $breakpoint)) {
        .#{$property}-#{$breakpoint}-#{$key} {
          #{$css-property}: #{$value} !important;
        }
      }
    }
  }
}

// Generate spacing classes without media queries
@include generate-spacing('m', 'margin', $spacers); // .m-1, .m-2, etc.
@include generate-spacing('mt', 'margin-top', $spacers);
@include generate-spacing('mb', 'margin-bottom', $spacers);
@include generate-spacing('ml', 'margin-left', $spacers);
@include generate-spacing('mr', 'margin-right', $spacers);

@include generate-spacing('p', 'padding', $spacers);
@include generate-spacing('pt', 'padding-top', $spacers);
@include generate-spacing('pb', 'padding-bottom', $spacers);
@include generate-spacing('pl', 'padding-left', $spacers);
@include generate-spacing('pr', 'padding-right', $spacers);

// Generate spacing classes with max-width media queries for different breakpoints
@each $breakpoint in map-keys($breakpoints) {
  @include generate-spacing('m', 'margin', $spacers, $breakpoint);
  @include generate-spacing('mt', 'margin-top', $spacers, $breakpoint);
  @include generate-spacing('mb', 'margin-bottom', $spacers, $breakpoint);
  @include generate-spacing('ml', 'margin-left', $spacers, $breakpoint);
  @include generate-spacing('mr', 'margin-right', $spacers, $breakpoint);

  @include generate-spacing('p', 'padding', $spacers, $breakpoint);
  @include generate-spacing('pt', 'padding-top', $spacers, $breakpoint);
  @include generate-spacing('pb', 'padding-bottom', $spacers, $breakpoint);
  @include generate-spacing('pl', 'padding-left', $spacers, $breakpoint);
  @include generate-spacing('pr', 'padding-right', $spacers, $breakpoint);
}

// Mixin to generate classes based on color map
@mixin gen-props($prefix, $property) {
  @each $color-name, $color in $colors {
    .#{$prefix}-#{$color-name} {
      #{$property}: $color;
    }
  }
}

// Mixin for generating visibility classes
@mixin generate-visibility-classes {
  @each $breakpoint, $value in $breakpoints {
    // Hidden classes
    .hidden-#{$breakpoint} {
      @media (max-width: #{$value}px) {
        display: none !important;
      }
    }

    // Visible classes
    .visible-#{$breakpoint} {
      display: none !important;
      @media (min-width: #{$value + 1}px) {
        display: block !important;
      }
    }
  }

  // Special case for the largest breakpoint (visible only)
  .visible-#{des-max} {
    display: none !important;
    @media (min-width: #{$des-max + 1}px) {
      display: block !important;
    }
  }
}

// Generate text color classes
@include gen-props('text', 'color');

// Generate background color classes
@include gen-props('bg', 'background');

// Use the mixin
@include generate-visibility-classes;
