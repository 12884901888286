$primary: #004c71;
$primaryVariant: #07405c;
$primaryAlt: #dfbb5d;
$primaryAltVariant: #d2ae52;
$secondary: #e1d0a5;
$secondaryAlt: #b2b1b1;
$dune: #e27c00;
$bean: #ab172b;
$moss: #799900;
$sky: #4d868e;
$error: #c13515;
$error20: rgba($error, 0.2);
$neutral01: #f9f7f7;
$neutral02: #f2f2f2;
$neutral03: #dddddd;
$neutral04: #d3d3d3;
$neutral05: #c2c2c2;
$neutral06: #b0b0b0;
$neutral07: #717171;
$neutral08: #5e5e5e;
$white: #ffffff;
$black: #000000;
$white50: rgba($white, 0.5);
$white80: rgba($white, 0.8);
$dark: #222222;
$dark5: rgba($dark, 0.05);
$dark30: rgba($dark, 0.3);
$transparent: transparent;

// Map of color names to their corresponding variables
$colors: (
  primary: $primary,
  primaryVariant: $primaryVariant,
  primaryAlt: $primaryAlt,
  primaryAltVariant: $primaryAltVariant,
  secondary: $secondary,
  secondaryAlt: $secondaryAlt,
  dune: $dune,
  bean: $bean,
  moss: $moss,
  sky: $sky,
  error: $error,
  neutral01: $neutral01,
  neutral02: $neutral02,
  neutral03: $neutral03,
  neutral04: $neutral04,
  neutral05: $neutral05,
  neutral06: $neutral06,
  neutral07: $neutral07,
  neutral08: $neutral08,
  white: $white,
  black: $black,
  white50: $white50,
  white80: $white80,
  dark: $dark,
  dark5: $dark5,
  dark30: $dark30,
  transparent: $transparent,
);

$maxWideScreenDesktop: 1440px;
$minWideScreenDesktop: 1300px;
$minDesktop: 1025px;
$maxTablet: 1024px;
$minTablet: 768px;
$bigMobile: 480px;
$maxMobile: 479px;

/* value settings for @media queries */
$mob-galaxyS5: 360;
$mob-iphone6: 375;
$mob: 480;
$mob-tab: 768;
$tab: 992;
$tab-des: 1200;
$des: 1440;
$des-max: 1580;
$height-1: 900;
$height-2: 420;

$breakpoints: (
  mob-galaxyS5: $mob-galaxyS5,
  mob-iphone6: $mob-iphone6,
  mob: $mob,
  mob-tab: $mob-tab,
  tab: $tab,
  tab-des: $tab-des,
  des: $des,
  des-max: $des-max,
);

/* value settings for spacing */
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.25,
  5: $spacer * 1.5,
  6: $spacer * 2.5,
  auto: 'auto',
);

$imageRadius: 20px;
